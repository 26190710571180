// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** All buttons are a flex container with a center alignment */\n._w-0 {\n  display: flex;\n  cursor: pointer;\n  position: relative;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  overflow: hidden;\n\n  border-radius: 8px;\n}\n._w-0._w-1,\n  ._w-0._w-2 {\n    border-radius: 6px;\n  }\n._w-0._w-1 {\n    height: 24px;\n  }\n._w-0._w-2 {\n    height: 28px;\n  }\n._w-0._w-3 {\n    height: 36px;\n  }\n._w-0._w-4 {\n    height: 40px;\n  }\n._w-0._w-5 {\n    height: 44px;\n  }\n\n._w-6._w-1 > * {\n      height: 12px;\n      width: 12px;\n    }\n\n._w-6._w-2 > * {\n      height: 14px;\n      width: 14px;\n    }\n\n._w-6._w-3 > *, ._w-6._w-4 > *, ._w-6._w-5 > * {\n      height: 20px;\n      width: 20px;\n    }\n\n/* Needed to make the large icon only button a perfect square */\n\n._w-6._w-4 {\n    padding-right: 2px;\n    padding-left: 2px;\n  }\n\n._w-7 > :nth-child(1) > button {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n  }\n\n._w-7 > :nth-child(2) > button {\n    border-left: 0px;\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n    margin-left: -1px;\n  }\n\n._w-8 {\n  width: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  align-self: baseline;\n}\n\n._w-9 {\n  width: 100%;\n}\n\n._w-9._w-a {\n    opacity: 0;\n  }\n", ""]);
// Exports
export var button = "_w-0";
export var xs = "_w-1";
export var sm = "_w-2";
export var md = "_w-3";
export var lg = "_w-4";
export var xl = "_w-5";
export var iconContainer = "_w-6";
export var dropdownButton = "_w-7";
export var spinner = "_w-8";
export var buttonContent = "_w-9";
export var pending = "_w-a";
export default ___CSS_LOADER_EXPORT___;
