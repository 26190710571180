// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._u-0 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.7);\n}\n\n._u-1 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n\n._u-2 {\n  position: absolute;\n  top: -30px;\n  right: 0;\n  cursor: pointer;\n  background-color: var(--redo-colors-background-bg-primary);\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n._u-2:hover {\n    background-color: var(--redo-surface-dark-color);\n  }\n\n._u-2:active {\n    background-color: var(--redo-light-gray);\n  }\n\n._u-3 {\n  color: var(--redo-colors-base-black);\n  width: 18px;\n  height: 18px;\n}\n", ""]);
// Exports
export var lightboxOverlay = "_u-0";
export var lightboxContent = "_u-1";
export var closeButton = "_u-2";
export var closeButtonIcon = "_u-3";
export default ___CSS_LOADER_EXPORT___;
